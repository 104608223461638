const tokens = {
  "fonts.body": {
    "value": "Inter, sans-serif",
    "variable": "var(--fonts-body)"
  },
  "fontSizes.12": {
    "value": "0.75rem",
    "variable": "var(--font-sizes-12)"
  },
  "fontSizes.14": {
    "value": "0.875rem",
    "variable": "var(--font-sizes-14)"
  },
  "fontSizes.16": {
    "value": "1rem",
    "variable": "var(--font-sizes-16)"
  },
  "fontSizes.18": {
    "value": "1.125rem",
    "variable": "var(--font-sizes-18)"
  },
  "fontSizes.22": {
    "value": "1.375rem",
    "variable": "var(--font-sizes-22)"
  },
  "fontSizes.26": {
    "value": "1.625rem",
    "variable": "var(--font-sizes-26)"
  },
  "fontSizes.32": {
    "value": "2rem",
    "variable": "var(--font-sizes-32)"
  },
  "fontSizes.48": {
    "value": "3rem",
    "variable": "var(--font-sizes-48)"
  },
  "lineHeights.16": {
    "value": "1rem",
    "variable": "var(--line-heights-16)"
  },
  "lineHeights.18": {
    "value": "1.125rem",
    "variable": "var(--line-heights-18)"
  },
  "lineHeights.20": {
    "value": "1.25rem",
    "variable": "var(--line-heights-20)"
  },
  "lineHeights.24": {
    "value": "1.5rem",
    "variable": "var(--line-heights-24)"
  },
  "lineHeights.28": {
    "value": "1.75rem",
    "variable": "var(--line-heights-28)"
  },
  "lineHeights.32": {
    "value": "2rem",
    "variable": "var(--line-heights-32)"
  },
  "lineHeights.36": {
    "value": "2.25rem",
    "variable": "var(--line-heights-36)"
  },
  "lineHeights.52": {
    "value": "3.25rem",
    "variable": "var(--line-heights-52)"
  },
  "lineHeights.80": {
    "value": "5rem",
    "variable": "var(--line-heights-80)"
  },
  "colors.white": {
    "value": "#FFFFFF",
    "variable": "var(--colors-white)"
  },
  "colors.brand.10": {
    "value": "#0A2920",
    "variable": "var(--colors-brand-10)"
  },
  "colors.brand.20": {
    "value": "#14523F",
    "variable": "var(--colors-brand-20)"
  },
  "colors.brand.30": {
    "value": "#1E7B5F",
    "variable": "var(--colors-brand-30)"
  },
  "colors.brand.40": {
    "value": "#28A47F",
    "variable": "var(--colors-brand-40)"
  },
  "colors.brand.60": {
    "value": "#5BD7B2",
    "variable": "var(--colors-brand-60)"
  },
  "colors.brand.70": {
    "value": "#84E1C5",
    "variable": "var(--colors-brand-70)"
  },
  "colors.brand.80": {
    "value": "#ADEBD8",
    "variable": "var(--colors-brand-80)"
  },
  "colors.brand.90": {
    "value": "#D6F5EC",
    "variable": "var(--colors-brand-90)"
  },
  "colors.brand.95": {
    "value": "#EAFAF5",
    "variable": "var(--colors-brand-95)"
  },
  "colors.brand": {
    "value": "#32CD9F",
    "variable": "var(--colors-brand)"
  },
  "colors.neutral.10": {
    "value": "#16181D",
    "variable": "var(--colors-neutral-10)"
  },
  "colors.neutral.30": {
    "value": "#434856",
    "variable": "var(--colors-neutral-30)"
  },
  "colors.neutral.40": {
    "value": "#5A6072",
    "variable": "var(--colors-neutral-40)"
  },
  "colors.neutral.50": {
    "value": "#70788F",
    "variable": "var(--colors-neutral-50)"
  },
  "colors.neutral.60": {
    "value": "#8D93A5",
    "variable": "var(--colors-neutral-60)"
  },
  "colors.neutral.70": {
    "value": "#A9AEBC",
    "variable": "var(--colors-neutral-70)"
  },
  "colors.neutral.80": {
    "value": "#C6C9D2",
    "variable": "var(--colors-neutral-80)"
  },
  "colors.neutral.90": {
    "value": "#E2E4E9",
    "variable": "var(--colors-neutral-90)"
  },
  "colors.neutral.95": {
    "value": "#F1F2F4",
    "variable": "var(--colors-neutral-95)"
  },
  "colors.neutral": {
    "value": "#2D3039",
    "variable": "var(--colors-neutral)"
  },
  "colors.blue.10": {
    "value": "#021731",
    "variable": "var(--colors-blue-10)"
  },
  "colors.blue.20": {
    "value": "#032E63",
    "variable": "var(--colors-blue-20)"
  },
  "colors.blue.30": {
    "value": "#054594",
    "variable": "var(--colors-blue-30)"
  },
  "colors.blue.40": {
    "value": "#065CC6",
    "variable": "var(--colors-blue-40)"
  },
  "colors.blue.60": {
    "value": "#398FF9",
    "variable": "var(--colors-blue-60)"
  },
  "colors.blue.70": {
    "value": "#6BABFA",
    "variable": "var(--colors-blue-70)"
  },
  "colors.blue.80": {
    "value": "#9CC7FC",
    "variable": "var(--colors-blue-80)"
  },
  "colors.blue.90": {
    "value": "#CEE3FD",
    "variable": "var(--colors-blue-90)"
  },
  "colors.blue.95": {
    "value": "#E6F1FE",
    "variable": "var(--colors-blue-95)"
  },
  "colors.blue": {
    "value": "#0874F7",
    "variable": "var(--colors-blue)"
  },
  "colors.purple.10": {
    "value": "#1A0231",
    "variable": "var(--colors-purple-10)"
  },
  "colors.purple.20": {
    "value": "#350561",
    "variable": "var(--colors-purple-20)"
  },
  "colors.purple.30": {
    "value": "#4F0792",
    "variable": "var(--colors-purple-30)"
  },
  "colors.purple.40": {
    "value": "#6909C3",
    "variable": "var(--colors-purple-40)"
  },
  "colors.purple.60": {
    "value": "#9C3CF6",
    "variable": "var(--colors-purple-60)"
  },
  "colors.purple.70": {
    "value": "#B56DF8",
    "variable": "var(--colors-purple-70)"
  },
  "colors.purple.80": {
    "value": "#CE9EFA",
    "variable": "var(--colors-purple-80)"
  },
  "colors.purple.90": {
    "value": "#E6CEFD",
    "variable": "var(--colors-purple-90)"
  },
  "colors.purple.95": {
    "value": "#F3E7FE",
    "variable": "var(--colors-purple-95)"
  },
  "colors.purple": {
    "value": "#830BF4",
    "variable": "var(--colors-purple)"
  },
  "colors.pink.10": {
    "value": "#2F0415",
    "variable": "var(--colors-pink-10)"
  },
  "colors.pink.20": {
    "value": "#5E082A",
    "variable": "var(--colors-pink-20)"
  },
  "colors.pink.30": {
    "value": "#8D0C40",
    "variable": "var(--colors-pink-30)"
  },
  "colors.pink.40": {
    "value": "#BC1055",
    "variable": "var(--colors-pink-40)"
  },
  "colors.pink.60": {
    "value": "#EF4388",
    "variable": "var(--colors-pink-60)"
  },
  "colors.pink.70": {
    "value": "#F372A6",
    "variable": "var(--colors-pink-70)"
  },
  "colors.pink.80": {
    "value": "#F7A1C3",
    "variable": "var(--colors-pink-80)"
  },
  "colors.pink.90": {
    "value": "#FBD0E1",
    "variable": "var(--colors-pink-90)"
  },
  "colors.pink.95": {
    "value": "#FDE8F0",
    "variable": "var(--colors-pink-95)"
  },
  "colors.pink": {
    "value": "#830BF4",
    "variable": "var(--colors-pink)"
  },
  "colors.orange.10": {
    "value": "#321501",
    "variable": "var(--colors-orange-10)"
  },
  "colors.orange.20": {
    "value": "#632B03",
    "variable": "var(--colors-orange-20)"
  },
  "colors.orange.30": {
    "value": "#954004",
    "variable": "var(--colors-orange-30)"
  },
  "colors.orange.40": {
    "value": "#C75605",
    "variable": "var(--colors-orange-40)"
  },
  "colors.orange.60": {
    "value": "#FA8938",
    "variable": "var(--colors-orange-60)"
  },
  "colors.orange.70": {
    "value": "#FBA66A",
    "variable": "var(--colors-orange-70)"
  },
  "colors.orange.80": {
    "value": "#FCC49C",
    "variable": "var(--colors-orange-80)"
  },
  "colors.orange.90": {
    "value": "#FEE1CD",
    "variable": "var(--colors-orange-90)"
  },
  "colors.orange.95": {
    "value": "#FEF8E6",
    "variable": "var(--colors-orange-95)"
  },
  "colors.orange": {
    "value": "#F96B06",
    "variable": "var(--colors-orange)"
  },
  "colors.red.10": {
    "value": "#2B0808",
    "variable": "var(--colors-red-10)"
  },
  "colors.red.20": {
    "value": "#570F0F",
    "variable": "var(--colors-red-20)"
  },
  "colors.red.30": {
    "value": "#821717",
    "variable": "var(--colors-red-30)"
  },
  "colors.red.40": {
    "value": "#AD1F1F",
    "variable": "var(--colors-red-40)"
  },
  "colors.red.60": {
    "value": "#E05252",
    "variable": "var(--colors-red-60)"
  },
  "colors.red.70": {
    "value": "#E87D7D",
    "variable": "var(--colors-red-70)"
  },
  "colors.red.80": {
    "value": "#F0A8A8",
    "variable": "var(--colors-red-80)"
  },
  "colors.red.90": {
    "value": "#F7D4D4",
    "variable": "var(--colors-red-90)"
  },
  "colors.red.95": {
    "value": "#FBE9E9",
    "variable": "var(--colors-red-95)"
  },
  "colors.red": {
    "value": "#D92626",
    "variable": "var(--colors-red)"
  },
  "colors.yellow.10": {
    "value": "#312502",
    "variable": "var(--colors-yellow-10)"
  },
  "colors.yellow.20": {
    "value": "#624B04",
    "variable": "var(--colors-yellow-20)"
  },
  "colors.yellow.30": {
    "value": "#947005",
    "variable": "var(--colors-yellow-30)"
  },
  "colors.yellow.40": {
    "value": "#C59507",
    "variable": "var(--colors-yellow-40)"
  },
  "colors.yellow.60": {
    "value": "#F8C83A",
    "variable": "var(--colors-yellow-60)"
  },
  "colors.yellow.70": {
    "value": "#FAD66B",
    "variable": "var(--colors-yellow-70)"
  },
  "colors.yellow.80": {
    "value": "#FBE49D",
    "variable": "var(--colors-yellow-80)"
  },
  "colors.yellow.90": {
    "value": "#FDF1CE",
    "variable": "var(--colors-yellow-90)"
  },
  "colors.yellow.95": {
    "value": "#FEF8E6",
    "variable": "var(--colors-yellow-95)"
  },
  "colors.yellow": {
    "value": "#F6BB09",
    "variable": "var(--colors-yellow)"
  },
  "colors.green.10": {
    "value": "#052E0E",
    "variable": "var(--colors-green-10)"
  },
  "colors.green.20": {
    "value": "#0A5C1C",
    "variable": "var(--colors-green-20)"
  },
  "colors.green.30": {
    "value": "#0F8A2A",
    "variable": "var(--colors-green-30)"
  },
  "colors.green.40": {
    "value": "#14B838",
    "variable": "var(--colors-green-40)"
  },
  "colors.green.60": {
    "value": "#F47EB6B",
    "variable": "var(--colors-green-60)"
  },
  "colors.green.70": {
    "value": "#75F090",
    "variable": "var(--colors-green-70)"
  },
  "colors.green.80": {
    "value": "#A3F5B5",
    "variable": "var(--colors-green-80)"
  },
  "colors.green.90": {
    "value": "#D1FADA",
    "variable": "var(--colors-green-90)"
  },
  "colors.green.95": {
    "value": "#E8FCEC",
    "variable": "var(--colors-green-95)"
  },
  "colors.green": {
    "value": "#19E646",
    "variable": "var(--colors-green)"
  },
  "spacing.0": {
    "value": "0rem",
    "variable": "var(--spacing-0)"
  },
  "spacing.2": {
    "value": "0.125rem",
    "variable": "var(--spacing-2)"
  },
  "spacing.4": {
    "value": "0.25rem",
    "variable": "var(--spacing-4)"
  },
  "spacing.6": {
    "value": "0.375rem",
    "variable": "var(--spacing-6)"
  },
  "spacing.8": {
    "value": "0.5rem",
    "variable": "var(--spacing-8)"
  },
  "spacing.10": {
    "value": "0.625rem",
    "variable": "var(--spacing-10)"
  },
  "spacing.12": {
    "value": "0.75rem",
    "variable": "var(--spacing-12)"
  },
  "spacing.14": {
    "value": "0.875rem",
    "variable": "var(--spacing-14)"
  },
  "spacing.16": {
    "value": "1rem",
    "variable": "var(--spacing-16)"
  },
  "spacing.20": {
    "value": "1.25rem",
    "variable": "var(--spacing-20)"
  },
  "spacing.24": {
    "value": "1.5rem",
    "variable": "var(--spacing-24)"
  },
  "spacing.28": {
    "value": "1.75rem",
    "variable": "var(--spacing-28)"
  },
  "spacing.32": {
    "value": "2rem",
    "variable": "var(--spacing-32)"
  },
  "spacing.36": {
    "value": "2.25rem",
    "variable": "var(--spacing-36)"
  },
  "spacing.40": {
    "value": "2.5rem",
    "variable": "var(--spacing-40)"
  },
  "spacing.44": {
    "value": "2.75rem",
    "variable": "var(--spacing-44)"
  },
  "spacing.48": {
    "value": "3rem",
    "variable": "var(--spacing-48)"
  },
  "spacing.56": {
    "value": "3.5rem",
    "variable": "var(--spacing-56)"
  },
  "spacing.64": {
    "value": "4rem",
    "variable": "var(--spacing-64)"
  },
  "spacing.80": {
    "value": "5rem",
    "variable": "var(--spacing-80)"
  },
  "spacing.96": {
    "value": "6rem",
    "variable": "var(--spacing-96)"
  },
  "spacing.112": {
    "value": "7rem",
    "variable": "var(--spacing-112)"
  },
  "spacing.128": {
    "value": "8rem",
    "variable": "var(--spacing-128)"
  },
  "spacing.144": {
    "value": "9rem",
    "variable": "var(--spacing-144)"
  },
  "spacing.160": {
    "value": "10rem",
    "variable": "var(--spacing-160)"
  },
  "spacing.176": {
    "value": "11rem",
    "variable": "var(--spacing-176)"
  },
  "spacing.192": {
    "value": "12rem",
    "variable": "var(--spacing-192)"
  },
  "spacing.208": {
    "value": "13rem",
    "variable": "var(--spacing-208)"
  },
  "spacing.224": {
    "value": "14rem",
    "variable": "var(--spacing-224)"
  },
  "spacing.240": {
    "value": "15rem",
    "variable": "var(--spacing-240)"
  },
  "spacing.256": {
    "value": "16rem",
    "variable": "var(--spacing-256)"
  },
  "spacing.288": {
    "value": "18rem",
    "variable": "var(--spacing-288)"
  },
  "spacing.320": {
    "value": "20rem",
    "variable": "var(--spacing-320)"
  },
  "spacing.384": {
    "value": "24rem",
    "variable": "var(--spacing-384)"
  },
  "radii.xs": {
    "value": "0.125rem",
    "variable": "var(--radii-xs)"
  },
  "radii.sm": {
    "value": "0.25rem",
    "variable": "var(--radii-sm)"
  },
  "radii.md": {
    "value": "0.375rem",
    "variable": "var(--radii-md)"
  },
  "radii.lg": {
    "value": "0.5rem",
    "variable": "var(--radii-lg)"
  },
  "radii.xl": {
    "value": "0.75rem",
    "variable": "var(--radii-xl)"
  },
  "radii.2xl": {
    "value": "1rem",
    "variable": "var(--radii-2xl)"
  },
  "radii.3xl": {
    "value": "1.5rem",
    "variable": "var(--radii-3xl)"
  },
  "radii.full": {
    "value": "9999px",
    "variable": "var(--radii-full)"
  },
  "shadows.1": {
    "value": "0px 0px 1px 0px rgba(22, 24, 29, 0.08), 0px 2px 2px 0px rgba(22, 24, 29, 0.07), 0px 3px 2px 0px rgba(22, 24, 29, 0.04), 0px 6px 2px 0px rgba(22, 24, 29, 0.01), 0px 9px 3px 0px rgba(22, 24, 29, 0.00);",
    "variable": "var(--shadows-1)"
  },
  "shadows.2": {
    "value": "0px 1px 2px 0px rgba(22, 24, 29, 0.08), 0px 4px 4px 0px rgba(22, 24, 29, 0.07), 0px 10px 6px 0px rgba(22, 24, 29, 0.04), 0px 18px 7px 0px rgba(22, 24, 29, 0.01), 0px 28px 8px 0px rgba(22, 24, 29, 0.00);",
    "variable": "var(--shadows-2)"
  },
  "shadows.3": {
    "value": "0px 2px 5px 0px rgba(22, 24, 29, 0.08), 0px 9px 9px 0px rgba(22, 24, 29, 0.07), 0px 20px 12px 0px rgba(22, 24, 29, 0.04), 0px 36px 14px 0px rgba(22, 24, 29, 0.01), 0px 56px 16px 0px rgba(22, 24, 29, 0.00);",
    "variable": "var(--shadows-3)"
  },
  "shadows.4": {
    "value": "0px 6px 13px 0px rgba(22, 24, 29, 0.10), 0px 24px 24px 0px rgba(22, 24, 29, 0.09), 0px 53px 32px 0px rgba(22, 24, 29, 0.05), 0px 95px 38px 0px rgba(22, 24, 29, 0.01), 0px 148px 41px 0px rgba(22, 24, 29, 0.00);",
    "variable": "var(--shadows-4)"
  },
  "shadows.5": {
    "value": "0px 8px 18px 0px rgba(22, 24, 29, 0.10), 0px 33px 33px 0px rgba(22, 24, 29, 0.09), 0px 74px 45px 0px rgba(22, 24, 29, 0.05), 0px 132px 53px 0px rgba(22, 24, 29, 0.01), 0px 207px 58px 0px rgba(22, 24, 29, 0.00);",
    "variable": "var(--shadows-5)"
  },
  "shadows.6": {
    "value": "0px 12px 26px 0px rgba(22, 24, 29, 0.10), 0px 47px 47px 0px rgba(22, 24, 29, 0.09), 0px 106px 64px 0px rgba(22, 24, 29, 0.05), 0px 189px 76px 0px rgba(22, 24, 29, 0.01), 0px 296px 83px 0px rgba(22, 24, 29, 0.00);",
    "variable": "var(--shadows-6)"
  },
  "animations.accordion-down": {
    "value": "accordion-down 0.2s ease-out",
    "variable": "var(--animations-accordion-down)"
  },
  "animations.accordion-up": {
    "value": "accordion-up 0.2s ease-out",
    "variable": "var(--animations-accordion-up)"
  },
  "zIndex.modal": {
    "value": 1000,
    "variable": "var(--z-index-modal)"
  },
  "breakpoints.sm": {
    "value": "30rem",
    "variable": "var(--breakpoints-sm)"
  },
  "breakpoints.md": {
    "value": "48rem",
    "variable": "var(--breakpoints-md)"
  },
  "breakpoints.lg": {
    "value": "64rem",
    "variable": "var(--breakpoints-lg)"
  },
  "breakpoints.xl": {
    "value": "80rem",
    "variable": "var(--breakpoints-xl)"
  },
  "breakpoints.2xl": {
    "value": "85.375rem",
    "variable": "var(--breakpoints-2xl)"
  },
  "breakpoints.3xl": {
    "value": "100rem",
    "variable": "var(--breakpoints-3xl)"
  },
  "sizes.breakpoint-sm": {
    "value": "30rem",
    "variable": "var(--sizes-breakpoint-sm)"
  },
  "sizes.breakpoint-md": {
    "value": "48rem",
    "variable": "var(--sizes-breakpoint-md)"
  },
  "sizes.breakpoint-lg": {
    "value": "64rem",
    "variable": "var(--sizes-breakpoint-lg)"
  },
  "sizes.breakpoint-xl": {
    "value": "80rem",
    "variable": "var(--sizes-breakpoint-xl)"
  },
  "sizes.breakpoint-2xl": {
    "value": "85.375rem",
    "variable": "var(--sizes-breakpoint-2xl)"
  },
  "sizes.breakpoint-3xl": {
    "value": "100rem",
    "variable": "var(--sizes-breakpoint-3xl)"
  },
  "colors.bg": {
    "value": "var(--colors-white)",
    "variable": "var(--colors-bg)"
  },
  "colors.text": {
    "value": "var(--colors-neutral)",
    "variable": "var(--colors-text)"
  },
  "colors.text.weak": {
    "value": "var(--colors-neutral-50)",
    "variable": "var(--colors-text-weak)"
  },
  "colors.text.success": {
    "value": "var(--colors-green-30)",
    "variable": "var(--colors-text-success)"
  },
  "colors.text.danger": {
    "value": "var(--colors-red-40)",
    "variable": "var(--colors-text-danger)"
  },
  "colors.text.warning": {
    "value": "var(--colors-orange-30)",
    "variable": "var(--colors-text-warning)"
  },
  "colors.text.info": {
    "value": "var(--colors-blue-30)",
    "variable": "var(--colors-text-info)"
  },
  "colors.border": {
    "value": "var(--colors-neutral-90)",
    "variable": "var(--colors-border)"
  },
  "colors.button.bg.primary": {
    "value": "var(--colors-neutral-10)",
    "variable": "var(--colors-button-bg-primary)"
  },
  "colors.button.bg.primary.hover": {
    "value": "var(--colors-neutral-30)",
    "variable": "var(--colors-button-bg-primary-hover)"
  },
  "colors.button.bg.primary.active": {
    "value": "var(--colors-neutral-50)",
    "variable": "var(--colors-button-bg-primary-active)"
  },
  "colors.button.bg.primary.status.danger": {
    "value": "var(--colors-red)",
    "variable": "var(--colors-button-bg-primary-status-danger)"
  },
  "colors.button.bg.primary.status.danger.hover": {
    "value": "var(--colors-red-40)",
    "variable": "var(--colors-button-bg-primary-status-danger-hover)"
  },
  "colors.button.bg.primary.status.danger.active": {
    "value": "var(--colors-red-30)",
    "variable": "var(--colors-button-bg-primary-status-danger-active)"
  },
  "colors.button.bg.secondary": {
    "value": "var(--colors-white)",
    "variable": "var(--colors-button-bg-secondary)"
  },
  "colors.button.bg.secondary.hover": {
    "value": "var(--colors-neutral-95)",
    "variable": "var(--colors-button-bg-secondary-hover)"
  },
  "colors.button.bg.secondary.active": {
    "value": "var(--colors-neutral-90)",
    "variable": "var(--colors-button-bg-secondary-active)"
  },
  "colors.button.bg.secondary.status.danger.hover": {
    "value": "var(--colors-red-95)",
    "variable": "var(--colors-button-bg-secondary-status-danger-hover)"
  },
  "colors.button.bg.secondary.status.danger.active": {
    "value": "var(--colors-red-90)",
    "variable": "var(--colors-button-bg-secondary-status-danger-active)"
  },
  "colors.button.bg.tertiary": {
    "value": "var(--colors-white)",
    "variable": "var(--colors-button-bg-tertiary)"
  },
  "colors.button.bg.tertiary.hover": {
    "value": "var(--colors-neutral-95)",
    "variable": "var(--colors-button-bg-tertiary-hover)"
  },
  "colors.button.bg.tertiary.active": {
    "value": "var(--colors-neutral-90)",
    "variable": "var(--colors-button-bg-tertiary-active)"
  },
  "colors.button.bg.tertiary.status.danger.hover": {
    "value": "var(--colors-red-95)",
    "variable": "var(--colors-button-bg-tertiary-status-danger-hover)"
  },
  "colors.button.bg.tertiary.status.danger.active": {
    "value": "var(--colors-red-90)",
    "variable": "var(--colors-button-bg-tertiary-status-danger-active)"
  },
  "colors.button.border.primary": {
    "value": "var(--colors-neutral-10)",
    "variable": "var(--colors-button-border-primary)"
  },
  "colors.button.border.primary.hover": {
    "value": "var(--colors-neutral-30)",
    "variable": "var(--colors-button-border-primary-hover)"
  },
  "colors.button.border.primary.active": {
    "value": "var(--colors-neutral-50)",
    "variable": "var(--colors-button-border-primary-active)"
  },
  "colors.button.border.primary.status.danger": {
    "value": "var(--colors-red)",
    "variable": "var(--colors-button-border-primary-status-danger)"
  },
  "colors.button.border.primary.status.danger.hover": {
    "value": "var(--colors-red-40)",
    "variable": "var(--colors-button-border-primary-status-danger-hover)"
  },
  "colors.button.border.primary.status.danger.active": {
    "value": "var(--colors-red-30)",
    "variable": "var(--colors-button-border-primary-status-danger-active)"
  },
  "colors.button.border.secondary": {
    "value": "var(--colors-neutral-10)",
    "variable": "var(--colors-button-border-secondary)"
  },
  "colors.button.border.secondary.status.danger": {
    "value": "var(--colors-red-40)",
    "variable": "var(--colors-button-border-secondary-status-danger)"
  },
  "colors.button.border.tertiary": {
    "value": "transparent",
    "variable": "var(--colors-button-border-tertiary)"
  },
  "colors.button.outline": {
    "value": "var(--colors-neutral-70)",
    "variable": "var(--colors-button-outline)"
  },
  "colors.button.outline.status.danger": {
    "value": "var(--colors-red-70)",
    "variable": "var(--colors-button-outline-status-danger)"
  },
  "colors.button.text.primary": {
    "value": "var(--colors-white)",
    "variable": "var(--colors-button-text-primary)"
  },
  "colors.button.text.primary.status.danger": {
    "value": "var(--colors-white)",
    "variable": "var(--colors-button-text-primary-status-danger)"
  },
  "colors.button.text.secondary": {
    "value": "var(--colors-neutral-10)",
    "variable": "var(--colors-button-text-secondary)"
  },
  "colors.button.text.secondary.status.danger": {
    "value": "var(--colors-red-40)",
    "variable": "var(--colors-button-text-secondary-status-danger)"
  },
  "colors.button.text.tertiary": {
    "value": "var(--colors-neutral-10)",
    "variable": "var(--colors-button-text-tertiary)"
  },
  "colors.button.text.tertiary.status.danger": {
    "value": "var(--colors-red-40)",
    "variable": "var(--colors-button-text-tertiary-status-danger)"
  },
  "colors.button.text.link.status.danger": {
    "value": "var(--colors-red-40)",
    "variable": "var(--colors-button-text-link-status-danger)"
  },
  "colors.success.10": {
    "value": "var(--colors-green-10)",
    "variable": "var(--colors-success-10)"
  },
  "colors.success.20": {
    "value": "var(--colors-green-20)",
    "variable": "var(--colors-success-20)"
  },
  "colors.success.30": {
    "value": "var(--colors-green-30)",
    "variable": "var(--colors-success-30)"
  },
  "colors.success.40": {
    "value": "var(--colors-green-40)",
    "variable": "var(--colors-success-40)"
  },
  "colors.success.60": {
    "value": "var(--colors-green-60)",
    "variable": "var(--colors-success-60)"
  },
  "colors.success.70": {
    "value": "var(--colors-green-70)",
    "variable": "var(--colors-success-70)"
  },
  "colors.success.80": {
    "value": "var(--colors-green-80)",
    "variable": "var(--colors-success-80)"
  },
  "colors.success.90": {
    "value": "var(--colors-green-90)",
    "variable": "var(--colors-success-90)"
  },
  "colors.success.95": {
    "value": "var(--colors-green-95)",
    "variable": "var(--colors-success-95)"
  },
  "colors.success": {
    "value": "var(--colors-green)",
    "variable": "var(--colors-success)"
  },
  "colors.warning.10": {
    "value": "var(--colors-yellow-10)",
    "variable": "var(--colors-warning-10)"
  },
  "colors.warning.20": {
    "value": "var(--colors-yellow-20)",
    "variable": "var(--colors-warning-20)"
  },
  "colors.warning.30": {
    "value": "var(--colors-yellow-30)",
    "variable": "var(--colors-warning-30)"
  },
  "colors.warning.40": {
    "value": "var(--colors-yellow-40)",
    "variable": "var(--colors-warning-40)"
  },
  "colors.warning.60": {
    "value": "var(--colors-yellow-60)",
    "variable": "var(--colors-warning-60)"
  },
  "colors.warning.70": {
    "value": "var(--colors-yellow-70)",
    "variable": "var(--colors-warning-70)"
  },
  "colors.warning.80": {
    "value": "var(--colors-yellow-80)",
    "variable": "var(--colors-warning-80)"
  },
  "colors.warning.90": {
    "value": "var(--colors-yellow-90)",
    "variable": "var(--colors-warning-90)"
  },
  "colors.warning.95": {
    "value": "var(--colors-yellow-95)",
    "variable": "var(--colors-warning-95)"
  },
  "colors.warning": {
    "value": "var(--colors-yellow)",
    "variable": "var(--colors-warning)"
  },
  "colors.danger.10": {
    "value": "var(--colors-red-10)",
    "variable": "var(--colors-danger-10)"
  },
  "colors.danger.20": {
    "value": "var(--colors-red-20)",
    "variable": "var(--colors-danger-20)"
  },
  "colors.danger.30": {
    "value": "var(--colors-red-30)",
    "variable": "var(--colors-danger-30)"
  },
  "colors.danger.40": {
    "value": "var(--colors-red-40)",
    "variable": "var(--colors-danger-40)"
  },
  "colors.danger.60": {
    "value": "var(--colors-red-60)",
    "variable": "var(--colors-danger-60)"
  },
  "colors.danger.70": {
    "value": "var(--colors-red-70)",
    "variable": "var(--colors-danger-70)"
  },
  "colors.danger.80": {
    "value": "var(--colors-red-80)",
    "variable": "var(--colors-danger-80)"
  },
  "colors.danger.90": {
    "value": "var(--colors-red-90)",
    "variable": "var(--colors-danger-90)"
  },
  "colors.danger.95": {
    "value": "var(--colors-red-95)",
    "variable": "var(--colors-danger-95)"
  },
  "colors.danger": {
    "value": "var(--colors-red)",
    "variable": "var(--colors-danger)"
  },
  "colors.overlay.background": {
    "value": "rgba(22, 24, 29, 0.3)",
    "variable": "var(--colors-overlay-background)"
  },
  "spacing.-2": {
    "value": "calc(var(--spacing-2) * -1)",
    "variable": "var(--spacing-2)"
  },
  "spacing.-4": {
    "value": "calc(var(--spacing-4) * -1)",
    "variable": "var(--spacing-4)"
  },
  "spacing.-6": {
    "value": "calc(var(--spacing-6) * -1)",
    "variable": "var(--spacing-6)"
  },
  "spacing.-8": {
    "value": "calc(var(--spacing-8) * -1)",
    "variable": "var(--spacing-8)"
  },
  "spacing.-10": {
    "value": "calc(var(--spacing-10) * -1)",
    "variable": "var(--spacing-10)"
  },
  "spacing.-12": {
    "value": "calc(var(--spacing-12) * -1)",
    "variable": "var(--spacing-12)"
  },
  "spacing.-14": {
    "value": "calc(var(--spacing-14) * -1)",
    "variable": "var(--spacing-14)"
  },
  "spacing.-16": {
    "value": "calc(var(--spacing-16) * -1)",
    "variable": "var(--spacing-16)"
  },
  "spacing.-20": {
    "value": "calc(var(--spacing-20) * -1)",
    "variable": "var(--spacing-20)"
  },
  "spacing.-24": {
    "value": "calc(var(--spacing-24) * -1)",
    "variable": "var(--spacing-24)"
  },
  "spacing.-28": {
    "value": "calc(var(--spacing-28) * -1)",
    "variable": "var(--spacing-28)"
  },
  "spacing.-32": {
    "value": "calc(var(--spacing-32) * -1)",
    "variable": "var(--spacing-32)"
  },
  "spacing.-36": {
    "value": "calc(var(--spacing-36) * -1)",
    "variable": "var(--spacing-36)"
  },
  "spacing.-40": {
    "value": "calc(var(--spacing-40) * -1)",
    "variable": "var(--spacing-40)"
  },
  "spacing.-44": {
    "value": "calc(var(--spacing-44) * -1)",
    "variable": "var(--spacing-44)"
  },
  "spacing.-48": {
    "value": "calc(var(--spacing-48) * -1)",
    "variable": "var(--spacing-48)"
  },
  "spacing.-56": {
    "value": "calc(var(--spacing-56) * -1)",
    "variable": "var(--spacing-56)"
  },
  "spacing.-64": {
    "value": "calc(var(--spacing-64) * -1)",
    "variable": "var(--spacing-64)"
  },
  "spacing.-80": {
    "value": "calc(var(--spacing-80) * -1)",
    "variable": "var(--spacing-80)"
  },
  "spacing.-96": {
    "value": "calc(var(--spacing-96) * -1)",
    "variable": "var(--spacing-96)"
  },
  "spacing.-112": {
    "value": "calc(var(--spacing-112) * -1)",
    "variable": "var(--spacing-112)"
  },
  "spacing.-128": {
    "value": "calc(var(--spacing-128) * -1)",
    "variable": "var(--spacing-128)"
  },
  "spacing.-144": {
    "value": "calc(var(--spacing-144) * -1)",
    "variable": "var(--spacing-144)"
  },
  "spacing.-160": {
    "value": "calc(var(--spacing-160) * -1)",
    "variable": "var(--spacing-160)"
  },
  "spacing.-176": {
    "value": "calc(var(--spacing-176) * -1)",
    "variable": "var(--spacing-176)"
  },
  "spacing.-192": {
    "value": "calc(var(--spacing-192) * -1)",
    "variable": "var(--spacing-192)"
  },
  "spacing.-208": {
    "value": "calc(var(--spacing-208) * -1)",
    "variable": "var(--spacing-208)"
  },
  "spacing.-224": {
    "value": "calc(var(--spacing-224) * -1)",
    "variable": "var(--spacing-224)"
  },
  "spacing.-240": {
    "value": "calc(var(--spacing-240) * -1)",
    "variable": "var(--spacing-240)"
  },
  "spacing.-256": {
    "value": "calc(var(--spacing-256) * -1)",
    "variable": "var(--spacing-256)"
  },
  "spacing.-288": {
    "value": "calc(var(--spacing-288) * -1)",
    "variable": "var(--spacing-288)"
  },
  "spacing.-320": {
    "value": "calc(var(--spacing-320) * -1)",
    "variable": "var(--spacing-320)"
  },
  "spacing.-384": {
    "value": "calc(var(--spacing-384) * -1)",
    "variable": "var(--spacing-384)"
  },
  "colors.colorPalette": {
    "value": "var(--colors-color-palette)",
    "variable": "var(--colors-color-palette)"
  },
  "colors.colorPalette.10": {
    "value": "var(--colors-color-palette-10)",
    "variable": "var(--colors-color-palette-10)"
  },
  "colors.colorPalette.20": {
    "value": "var(--colors-color-palette-20)",
    "variable": "var(--colors-color-palette-20)"
  },
  "colors.colorPalette.30": {
    "value": "var(--colors-color-palette-30)",
    "variable": "var(--colors-color-palette-30)"
  },
  "colors.colorPalette.40": {
    "value": "var(--colors-color-palette-40)",
    "variable": "var(--colors-color-palette-40)"
  },
  "colors.colorPalette.60": {
    "value": "var(--colors-color-palette-60)",
    "variable": "var(--colors-color-palette-60)"
  },
  "colors.colorPalette.70": {
    "value": "var(--colors-color-palette-70)",
    "variable": "var(--colors-color-palette-70)"
  },
  "colors.colorPalette.80": {
    "value": "var(--colors-color-palette-80)",
    "variable": "var(--colors-color-palette-80)"
  },
  "colors.colorPalette.90": {
    "value": "var(--colors-color-palette-90)",
    "variable": "var(--colors-color-palette-90)"
  },
  "colors.colorPalette.95": {
    "value": "var(--colors-color-palette-95)",
    "variable": "var(--colors-color-palette-95)"
  },
  "colors.colorPalette.50": {
    "value": "var(--colors-color-palette-50)",
    "variable": "var(--colors-color-palette-50)"
  },
  "colors.colorPalette.weak": {
    "value": "var(--colors-color-palette-weak)",
    "variable": "var(--colors-color-palette-weak)"
  },
  "colors.colorPalette.success": {
    "value": "var(--colors-color-palette-success)",
    "variable": "var(--colors-color-palette-success)"
  },
  "colors.colorPalette.danger": {
    "value": "var(--colors-color-palette-danger)",
    "variable": "var(--colors-color-palette-danger)"
  },
  "colors.colorPalette.warning": {
    "value": "var(--colors-color-palette-warning)",
    "variable": "var(--colors-color-palette-warning)"
  },
  "colors.colorPalette.info": {
    "value": "var(--colors-color-palette-info)",
    "variable": "var(--colors-color-palette-info)"
  },
  "colors.colorPalette.bg.primary": {
    "value": "var(--colors-color-palette-bg-primary)",
    "variable": "var(--colors-color-palette-bg-primary)"
  },
  "colors.colorPalette.primary": {
    "value": "var(--colors-color-palette-primary)",
    "variable": "var(--colors-color-palette-primary)"
  },
  "colors.colorPalette.bg.primary.hover": {
    "value": "var(--colors-color-palette-bg-primary-hover)",
    "variable": "var(--colors-color-palette-bg-primary-hover)"
  },
  "colors.colorPalette.primary.hover": {
    "value": "var(--colors-color-palette-primary-hover)",
    "variable": "var(--colors-color-palette-primary-hover)"
  },
  "colors.colorPalette.hover": {
    "value": "var(--colors-color-palette-hover)",
    "variable": "var(--colors-color-palette-hover)"
  },
  "colors.colorPalette.bg.primary.active": {
    "value": "var(--colors-color-palette-bg-primary-active)",
    "variable": "var(--colors-color-palette-bg-primary-active)"
  },
  "colors.colorPalette.primary.active": {
    "value": "var(--colors-color-palette-primary-active)",
    "variable": "var(--colors-color-palette-primary-active)"
  },
  "colors.colorPalette.active": {
    "value": "var(--colors-color-palette-active)",
    "variable": "var(--colors-color-palette-active)"
  },
  "colors.colorPalette.bg.primary.status.danger": {
    "value": "var(--colors-color-palette-bg-primary-status-danger)",
    "variable": "var(--colors-color-palette-bg-primary-status-danger)"
  },
  "colors.colorPalette.primary.status.danger": {
    "value": "var(--colors-color-palette-primary-status-danger)",
    "variable": "var(--colors-color-palette-primary-status-danger)"
  },
  "colors.colorPalette.status.danger": {
    "value": "var(--colors-color-palette-status-danger)",
    "variable": "var(--colors-color-palette-status-danger)"
  },
  "colors.colorPalette.bg.primary.status.danger.hover": {
    "value": "var(--colors-color-palette-bg-primary-status-danger-hover)",
    "variable": "var(--colors-color-palette-bg-primary-status-danger-hover)"
  },
  "colors.colorPalette.primary.status.danger.hover": {
    "value": "var(--colors-color-palette-primary-status-danger-hover)",
    "variable": "var(--colors-color-palette-primary-status-danger-hover)"
  },
  "colors.colorPalette.status.danger.hover": {
    "value": "var(--colors-color-palette-status-danger-hover)",
    "variable": "var(--colors-color-palette-status-danger-hover)"
  },
  "colors.colorPalette.danger.hover": {
    "value": "var(--colors-color-palette-danger-hover)",
    "variable": "var(--colors-color-palette-danger-hover)"
  },
  "colors.colorPalette.bg.primary.status.danger.active": {
    "value": "var(--colors-color-palette-bg-primary-status-danger-active)",
    "variable": "var(--colors-color-palette-bg-primary-status-danger-active)"
  },
  "colors.colorPalette.primary.status.danger.active": {
    "value": "var(--colors-color-palette-primary-status-danger-active)",
    "variable": "var(--colors-color-palette-primary-status-danger-active)"
  },
  "colors.colorPalette.status.danger.active": {
    "value": "var(--colors-color-palette-status-danger-active)",
    "variable": "var(--colors-color-palette-status-danger-active)"
  },
  "colors.colorPalette.danger.active": {
    "value": "var(--colors-color-palette-danger-active)",
    "variable": "var(--colors-color-palette-danger-active)"
  },
  "colors.colorPalette.bg.secondary": {
    "value": "var(--colors-color-palette-bg-secondary)",
    "variable": "var(--colors-color-palette-bg-secondary)"
  },
  "colors.colorPalette.secondary": {
    "value": "var(--colors-color-palette-secondary)",
    "variable": "var(--colors-color-palette-secondary)"
  },
  "colors.colorPalette.bg.secondary.hover": {
    "value": "var(--colors-color-palette-bg-secondary-hover)",
    "variable": "var(--colors-color-palette-bg-secondary-hover)"
  },
  "colors.colorPalette.secondary.hover": {
    "value": "var(--colors-color-palette-secondary-hover)",
    "variable": "var(--colors-color-palette-secondary-hover)"
  },
  "colors.colorPalette.bg.secondary.active": {
    "value": "var(--colors-color-palette-bg-secondary-active)",
    "variable": "var(--colors-color-palette-bg-secondary-active)"
  },
  "colors.colorPalette.secondary.active": {
    "value": "var(--colors-color-palette-secondary-active)",
    "variable": "var(--colors-color-palette-secondary-active)"
  },
  "colors.colorPalette.bg.secondary.status.danger.hover": {
    "value": "var(--colors-color-palette-bg-secondary-status-danger-hover)",
    "variable": "var(--colors-color-palette-bg-secondary-status-danger-hover)"
  },
  "colors.colorPalette.secondary.status.danger.hover": {
    "value": "var(--colors-color-palette-secondary-status-danger-hover)",
    "variable": "var(--colors-color-palette-secondary-status-danger-hover)"
  },
  "colors.colorPalette.bg.secondary.status.danger.active": {
    "value": "var(--colors-color-palette-bg-secondary-status-danger-active)",
    "variable": "var(--colors-color-palette-bg-secondary-status-danger-active)"
  },
  "colors.colorPalette.secondary.status.danger.active": {
    "value": "var(--colors-color-palette-secondary-status-danger-active)",
    "variable": "var(--colors-color-palette-secondary-status-danger-active)"
  },
  "colors.colorPalette.bg.tertiary": {
    "value": "var(--colors-color-palette-bg-tertiary)",
    "variable": "var(--colors-color-palette-bg-tertiary)"
  },
  "colors.colorPalette.tertiary": {
    "value": "var(--colors-color-palette-tertiary)",
    "variable": "var(--colors-color-palette-tertiary)"
  },
  "colors.colorPalette.bg.tertiary.hover": {
    "value": "var(--colors-color-palette-bg-tertiary-hover)",
    "variable": "var(--colors-color-palette-bg-tertiary-hover)"
  },
  "colors.colorPalette.tertiary.hover": {
    "value": "var(--colors-color-palette-tertiary-hover)",
    "variable": "var(--colors-color-palette-tertiary-hover)"
  },
  "colors.colorPalette.bg.tertiary.active": {
    "value": "var(--colors-color-palette-bg-tertiary-active)",
    "variable": "var(--colors-color-palette-bg-tertiary-active)"
  },
  "colors.colorPalette.tertiary.active": {
    "value": "var(--colors-color-palette-tertiary-active)",
    "variable": "var(--colors-color-palette-tertiary-active)"
  },
  "colors.colorPalette.bg.tertiary.status.danger.hover": {
    "value": "var(--colors-color-palette-bg-tertiary-status-danger-hover)",
    "variable": "var(--colors-color-palette-bg-tertiary-status-danger-hover)"
  },
  "colors.colorPalette.tertiary.status.danger.hover": {
    "value": "var(--colors-color-palette-tertiary-status-danger-hover)",
    "variable": "var(--colors-color-palette-tertiary-status-danger-hover)"
  },
  "colors.colorPalette.bg.tertiary.status.danger.active": {
    "value": "var(--colors-color-palette-bg-tertiary-status-danger-active)",
    "variable": "var(--colors-color-palette-bg-tertiary-status-danger-active)"
  },
  "colors.colorPalette.tertiary.status.danger.active": {
    "value": "var(--colors-color-palette-tertiary-status-danger-active)",
    "variable": "var(--colors-color-palette-tertiary-status-danger-active)"
  },
  "colors.colorPalette.border.primary": {
    "value": "var(--colors-color-palette-border-primary)",
    "variable": "var(--colors-color-palette-border-primary)"
  },
  "colors.colorPalette.border.primary.hover": {
    "value": "var(--colors-color-palette-border-primary-hover)",
    "variable": "var(--colors-color-palette-border-primary-hover)"
  },
  "colors.colorPalette.border.primary.active": {
    "value": "var(--colors-color-palette-border-primary-active)",
    "variable": "var(--colors-color-palette-border-primary-active)"
  },
  "colors.colorPalette.border.primary.status.danger": {
    "value": "var(--colors-color-palette-border-primary-status-danger)",
    "variable": "var(--colors-color-palette-border-primary-status-danger)"
  },
  "colors.colorPalette.border.primary.status.danger.hover": {
    "value": "var(--colors-color-palette-border-primary-status-danger-hover)",
    "variable": "var(--colors-color-palette-border-primary-status-danger-hover)"
  },
  "colors.colorPalette.border.primary.status.danger.active": {
    "value": "var(--colors-color-palette-border-primary-status-danger-active)",
    "variable": "var(--colors-color-palette-border-primary-status-danger-active)"
  },
  "colors.colorPalette.border.secondary": {
    "value": "var(--colors-color-palette-border-secondary)",
    "variable": "var(--colors-color-palette-border-secondary)"
  },
  "colors.colorPalette.border.secondary.status.danger": {
    "value": "var(--colors-color-palette-border-secondary-status-danger)",
    "variable": "var(--colors-color-palette-border-secondary-status-danger)"
  },
  "colors.colorPalette.secondary.status.danger": {
    "value": "var(--colors-color-palette-secondary-status-danger)",
    "variable": "var(--colors-color-palette-secondary-status-danger)"
  },
  "colors.colorPalette.border.tertiary": {
    "value": "var(--colors-color-palette-border-tertiary)",
    "variable": "var(--colors-color-palette-border-tertiary)"
  },
  "colors.colorPalette.outline": {
    "value": "var(--colors-color-palette-outline)",
    "variable": "var(--colors-color-palette-outline)"
  },
  "colors.colorPalette.outline.status.danger": {
    "value": "var(--colors-color-palette-outline-status-danger)",
    "variable": "var(--colors-color-palette-outline-status-danger)"
  },
  "colors.colorPalette.text.primary": {
    "value": "var(--colors-color-palette-text-primary)",
    "variable": "var(--colors-color-palette-text-primary)"
  },
  "colors.colorPalette.text.primary.status.danger": {
    "value": "var(--colors-color-palette-text-primary-status-danger)",
    "variable": "var(--colors-color-palette-text-primary-status-danger)"
  },
  "colors.colorPalette.text.secondary": {
    "value": "var(--colors-color-palette-text-secondary)",
    "variable": "var(--colors-color-palette-text-secondary)"
  },
  "colors.colorPalette.text.secondary.status.danger": {
    "value": "var(--colors-color-palette-text-secondary-status-danger)",
    "variable": "var(--colors-color-palette-text-secondary-status-danger)"
  },
  "colors.colorPalette.text.tertiary": {
    "value": "var(--colors-color-palette-text-tertiary)",
    "variable": "var(--colors-color-palette-text-tertiary)"
  },
  "colors.colorPalette.text.tertiary.status.danger": {
    "value": "var(--colors-color-palette-text-tertiary-status-danger)",
    "variable": "var(--colors-color-palette-text-tertiary-status-danger)"
  },
  "colors.colorPalette.tertiary.status.danger": {
    "value": "var(--colors-color-palette-tertiary-status-danger)",
    "variable": "var(--colors-color-palette-tertiary-status-danger)"
  },
  "colors.colorPalette.text.link.status.danger": {
    "value": "var(--colors-color-palette-text-link-status-danger)",
    "variable": "var(--colors-color-palette-text-link-status-danger)"
  },
  "colors.colorPalette.link.status.danger": {
    "value": "var(--colors-color-palette-link-status-danger)",
    "variable": "var(--colors-color-palette-link-status-danger)"
  },
  "colors.colorPalette.background": {
    "value": "var(--colors-color-palette-background)",
    "variable": "var(--colors-color-palette-background)"
  }
}

export function token(path, fallback) {
  return tokens[path]?.value || fallback
}

function tokenVar(path, fallback) {
  return tokens[path]?.variable || fallback
}

token.var = tokenVar